@import '@/style/variables.scss';

.notFound {
  display: flex;
  position: relative;
  min-height: 100vh;

  &__video {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
  }

  &__title {
    font-size: 250px;
    font-weight: 700;
    line-height: 1.1;
    margin: 0 auto 25px;

    @media (max-width: $smallDesk) {
      font-size: 50px;
    }
  }

  &__subtitle {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 20px;

    @media (max-width: $smallDesk) {
      font-size: 30px;
    }
  }

  &__text {
    font-size: 30px;
    line-height: 1.2;
    margin: 0 auto 30px;
    max-width: 715px;

    @media (max-width: $smallDesk) {
      font-size: 20px;
    }
  }

  &__link {
    font-size: 25px;
    position: relative;

    @media (max-width: $smallDesk) {
      font-size: 18px;
    }

    &:before {
      content: '';
      width: 0;
      height: 2px;
      position: absolute;
      bottom: -3px;
      left: 50%;
      background: #fff;
      transition: width 0.4s, left 0.4s;
    }
    &:hover {
      &:before {
        width: 100%;
        left: 0;
      }
    }
  }
}
